<template>
  <div class="mw-100 px-2 pt-1 pb-3">
    <div class="section">
      <div
        class="btn-responsive"
        v-if="!hasTablePermission && hasAddPermission"
      >
        <router-link
          to="/offline-clinic-booking/appointment"
          class="btn btn-primary rounded bg-primary-blue"
        >
          <span>
            <i class="fa fa-plus"></i>
          </span>
          New Appointment
        </router-link>
      </div>
      <div v-if="hasTablePermission" class="mb-1">
        <TabsV2
          :tabs="[
            todayTab,
            tomorrowTab,
            { value: 'ALL', label: 'All Treatments' },
          ]"
          :activeTab="activeTab"
          @change="handleChangeTab"
        ></TabsV2>
      </div>
      <div class="card" v-if="hasTablePermission">
        <div
          class="
            card-body
            p-1
            d-flex
            flex-row
            justify-content-between
            gap-1
            align-items-end
            flex-wrap
          "
        >
          <div
            class="
              d-flex
              flex-column
              align-items-start
              gap-1
              flex-md-row
              align-items-md-end
              flex-wrap
            "
          >
            <div class="d-flex flex-column gap-0.25">
              <p class="mb-0 f-12 font-600">Clinic Name</p>
              <InputSelect
                :options="clinicNameOptions"
                v-model="clinic"
                @input="getList()"
                width="200px"
              />
            </div>
            <div class="d-flex flex-column gap-0.25">
              <p class="mb-0 f-12 font-600">Clinic Team Required</p>
              <InputMultiSelectCheckbox
                :options="clinicTeamRoleOptions"
                v-model="clinicTeamRequired"
                @input="getList()"
                width="200px"
              />
            </div>
            <div class="d-flex flex-column gap-0.25">
              <p class="mb-0 f-12 font-600">Search Appointment</p>
              <div
                class="
                  d-flex
                  flex-column
                  align-items-start
                  flex-md-row
                  align-items-md-end
                "
              >
                <InputSelect
                  :options="[
                    { value: 'userName', label: 'User Name' },
                    { value: 'mobileNumber', label: 'Phone Number' },
                    { value: 'bookingNumber', label: 'Booking ID' },
                    { value: 'email', label: 'Email' },
                  ]"
                  v-model="searchParam"
                  @input="handleChangeSearchParam()"
                  :clearable="false"
                  width="140px"
                  :styleObject="{
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                  }"
                />
                <InputText
                  v-model="search"
                  @input="handleSearch"
                  placeholder="Search..."
                  iconClass="fa-search"
                  :styleObject="{
                    width: '300px',
                    borderTopLeftRadius: '0px',
                    borderBottomLeftRadius: '0px',
                    borderLeft: 'none',
                  }"
                />
              </div>
            </div>
            <MoreFilter
              v-model="moreFilter"
              @input="getList()"
              :withDateFilter="activeTab.value === 'ALL'"
              :defaultDateFilter="getDefaultDate(activeTab.value)"
              :clinicId="clinic ? clinic.value : null"
            ></MoreFilter>
          </div>
          <div class="btn-responsive" v-if="hasAddPermission">
            <router-link
              to="/offline-clinic-booking/appointment"
              class="btn btn-primary rounded bg-primary-blue"
            >
              <span>
                <i class="fa fa-plus"></i>
              </span>
              New Appointment
            </router-link>
          </div>
        </div>
      </div>

      <div v-if="hasTablePermission" class="card">
        <TableV2>
          <colgroup>
            <col style="width: 300px" />
            <col style="width: 150px" />
            <col style="width: 120px" />
            <col style="min-width: 320px" />
            <col style="min-width: 320px" />
            <col style="width: 150px; min-width: 150px" />
            <col style="width: 120px" />
          </colgroup>
          <thead>
            <tr>
              <th>User Information</th>
              <th>ID & Status</th>
              <th>Time</th>
              <th>Treatment Detail</th>
              <th>Clinic & Room</th>
              <th>Clinic Team</th>
              <th class="text-center">Action</th>
            </tr>
          </thead>
          <tbody v-if="groupedRows.length === 0">
            <tr>
              <td colspan="7" class="text-center">No data</td>
            </tr>
          </tbody>
          <tbody
            v-for="row in groupedRows"
            :key="row.bookingNumber"
            class="tbody"
          >
            <tr
              v-for="(bookingDetail, bookingDetailIdx) in row.bookingDetails"
              :key="bookingDetail.id"
            >
              <td
                v-if="bookingDetailIdx === 0"
                :rowspan="row.bookingDetails.length"
              >
                <div class="mb-0.25 d-flex align-items-center gap-0.25">
                  <div class="d-flex align-items-center" style="gap: 4px;">
                      <span v-if="row.user.isVip">👑</span>
                      <img
                          v-if="row.user.isSubscribed"
                          src="/assets/img/icon/diri-xtra-logo.svg"
                          width="20"
                          height="20"
                          alt=""
                      />
                      <p class="f-14 m-0 font-600 text-dark text-truncate">
                          {{ row.user.name }}
                      </p>
                  </div>
                  <div
                    v-if="!row.user.hasTreatmentHistory"
                    class="flex-shrink-0"
                  >
                    <Badge textColor="#FFFFFF" bgColor="#798EA5">New</Badge>
                  </div>
                </div>
                <span>{{ row.user.mobileNumber }}</span>
              </td>
              <td
                v-if="bookingDetailIdx === 0"
                :rowspan="row.bookingDetails.length"
              >
                <div class="d-flex flex-column align-items-start gap-0.25">
                  <router-link
                    :to="`/offline-clinic-booking/${row.bookingNumber}`"
                    class="text-primary-blue"
                  >
                    {{ row.bookingNumber }}
                  </router-link>
                  <Badge
                    :textColor="row.status.textColor"
                    :bgColor="row.status.backgroundColor"
                    >{{ row.status.label }}
                  </Badge>
                </div>
              </td>
              <td style="white-space: nowrap">
                <p v-if="activeTab.value === 'ALL'" class="mb-0.25 f-14">
                  {{ row.date | moment('D MMM YYYY') }}
                </p>
                <p class="mb-0 f-14">
                  {{ bookingDetail.startTime }} - {{ bookingDetail.endTime }}
                </p>
              </td>
              <td
                :style="{
                  backgroundColor: getTreatmentDetailBgColor(
                    bookingDetail.status
                  ),
                }"
              >
                {{ bookingDetail.treatmentDetailTitle }}
              </td>
              <td>
                <p class="text-truncate f-14 mb-0.25 font-600 text-dark">
                  {{ row.clinic.name }}
                </p>
                <div class="d-flex align-items-center gap-0.25">
                  <p class="text-truncate f-14 mb-0">
                    {{ bookingDetail.clinicRoom.name }} -
                    {{ getRoomTypeLabel(bookingDetail.clinicRoom.type) }}
                  </p>
                  <div
                    v-if="!bookingDetail.clinicRoom.isAvailable"
                    class="flex-shrink-0"
                  >
                    <Badge textColor="#FFFFFF" bgColor="#FFAE62">Used</Badge>
                  </div>
                  <img
                    v-if="
                      hasEditPermission &&
                      !['COMPLETED', 'CANCELED'].includes(row.status.enum) &&
                      bookingDetail.status.enum === 'NOT_STARTED'
                    "
                    src="/assets/img/icon/edit-icon-blue.svg"
                    width="16"
                    height="16"
                    alt=""
                    class="ml-0.5 flex-shrink-0 cursor-pointer"
                    @click="
                      handleEditRoom({
                        bookingDetailId: bookingDetail.id,
                        clinicRoom: bookingDetail.clinicRoom,
                      })
                    "
                  />
                </div>
              </td>
              <td>
                <div class="d-flex flex-wrap gap-0.5">
                  <ClinicTeamChips
                    v-for="(
                      clinicTeamRole, clinicTeamRoleIdx
                    ) in bookingDetail.requiredClinicTeamRoles"
                    :key="clinicTeamRoleIdx"
                    :clinicTeam="clinicTeamRole.assignedClinicTeam"
                    :role="{
                      slug: clinicTeamRole.slug,
                      name: clinicTeamRole.name,
                    }"
                    :editable="
                      hasEditPermission &&
                      !['COMPLETED', 'CANCELED'].includes(row.status.enum) &&
                      bookingDetail.status.enum !== 'CANCELED'
                    "
                    @clickEdit="
                      handleEditClinicTeam({
                        bookingDetailId: bookingDetail.id,
                        clinicTeamRoles: bookingDetail.requiredClinicTeamRoles,
                        selectedTeamRole: {
                          slug: clinicTeamRole.slug,
                          name: clinicTeamRole.name,
                        },
                      })
                    "
                  ></ClinicTeamChips>
                </div>
              </td>
              <td
                v-if="bookingDetailIdx === 0"
                :rowspan="row.bookingDetails.length"
              >
                <ActionButton
                  :booking="row"
                  :hasEditPermission="hasEditPermission"
                  @finishAction="getList(results.currentPage)"
                ></ActionButton>
              </td>
            </tr>
          </tbody>
        </TableV2>
      </div>

      <div v-if="groupedRows.length > 0" class="d-flex justify-content-end">
        <Pagination
          :currentPage="results.currentPage"
          :totalPages="results.totalPages"
          @change="handleChangePage"
        ></Pagination>
      </div>

      <EditRoomModal
        :isOpen="isEditRoomModalOpen"
        :bookingDetailId="editedRoomBookingDetailId"
        :selectedValue="editedRoomSelectedValue"
        @close="isEditRoomModalOpen = false"
        @save="getList(results.currentPage)"
      ></EditRoomModal>

      <EditClinicTeamModal
        :isOpen="isEditClinicTeamModalOpen"
        :bookingDetailId="editedClinicTeamBookingDetailId"
        :selectedValues="editedClinicTeamSelectedValues"
        :activeTab="editedClinicTeamActiveTab"
        @close="isEditClinicTeamModalOpen = false"
        @save="getList(results.currentPage)"
      ></EditClinicTeamModal>
    </div>
  </div>
</template>

<script>
import InputSelect from '@/components/InputSelect';
import InputMultiSelectCheckbox from '@/components/InputMultiSelectCheckbox';
import InputText from '@/components/InputText';
import TableV2 from '@/components/TableV2';
import Badge from '@/components/Badge';
import TabsV2 from '@/components/TabsV2';
import Pagination from '@/components/Pagination';
import MoreFilter from './components/MoreFilter.vue';
import ClinicTeamChips from './components/ClinicTeamChips.vue';
import EditRoomModal from './components/EditRoomModal.vue';
import EditClinicTeamModal from './components/EditClinicTeamModal.vue';
import ActionButton from './components/ActionButton.vue';
import { debounce } from 'debounce';

export default {
  components: {
    InputSelect,
    InputMultiSelectCheckbox,
    InputText,
    TableV2,
    Badge,
    TabsV2,
    Pagination,
    MoreFilter,
    ClinicTeamChips,
    EditRoomModal,
    EditClinicTeamModal,
    ActionButton,
  },
  data() {
    return {
      results: {
        rows: [],
        currentPage: 1,
        totalPages: 1,
      },
      limit: 25,
      getListControler: new AbortController(),
      // search & filter
      activeTab: {
        value: 'TODAY',
        label: `Today, ${this.$moment().format('D MMM YYYY')}`,
      },
      clinic: null,
      clinicTeamRequired: [],
      searchParam: { value: 'userName', label: 'User Name' },
      search: '',
      moreFilter: {
        date: [
          this.$moment().format('YYYY-MM-DD'),
          this.$moment().format('YYYY-MM-DD'),
        ],
        bookingStatus: [],
        bookingDetailStatus: [],
        clinicTeams: [],
        roomTypes: [],
        rooms: [],
        treatments: [],
      },
      clinicNameOptions: [],
      clinicTeamRoleOptions: [],
      // edit room modal
      isEditRoomModalOpen: false,
      editedRoomBookingDetailId: null,
      editedRoomSelectedValue: null,
      // edit clinic team modal
      isEditClinicTeamModalOpen: false,
      editedClinicTeamBookingDetailId: null,
      editedClinicTeamSelectedValues: [],
      editedClinicTeamActiveTab: null,
    };
  },
  computed: {
    permissions() {
      return this.$store.getters.permissions;
    },
    hasViewPermission() {
      return this.permissions.includes('offline_clinic_booking:view');
    },
    hasEditPermission() {
      return this.permissions.includes('offline_clinic_booking:edit');
    },
    hasAddPermission() {
      return this.permissions.includes('offline_clinic_booking:add');
    },
    hasTablePermission() {
      return this.hasViewPermission || this.hasEditPermission;
    },
    sanitizedSearch() {
      if (this.searchParam.value === 'email') {
        return this.search.replace(/ /g, '');
      }
      if (this.searchParam.value === 'mobileNumber') {
        const trimmedValue = this.search.replace(/ /g, '');
        if (trimmedValue.startsWith('+62')) {
          return `0${trimmedValue.slice(3)}`;
        }
        return trimmedValue;
      }
      if (this.searchParam.value === 'bookingNumber') {
        return this.search.toUpperCase();
      }
      return this.search;
    },
    todayTab() {
      return {
        value: 'TODAY',
        label: `Today, ${this.$moment().format('D MMM YYYY')}`,
      };
    },
    tomorrowTab() {
      return {
        value: 'TOMORROW',
        label: `Upcoming, ${this.$moment()
          .add(1, 'days')
          .format('D MMM YYYY')}`,
      };
    },
    groupedRows() {
      const bookings = [];

      this.results.rows.forEach((row) => {
        const lastIndex = bookings.length - 1;
        const { booking, ...bookingDetail } = row;

        if (lastIndex === -1 || booking.id !== bookings[lastIndex].id) {
          bookings.push({
            ...booking,
            bookingDetails: [bookingDetail],
          });
        } else {
          bookings[lastIndex].bookingDetails.push(bookingDetail);
        }
      });

      return bookings;
    },
  },
  mounted() {
    this.$moment.lang('en');
    if (this.hasTablePermission) {
      this.getList();
      this.getClinicNameOptions();
      this.getClinicTeamRoleOptions();
    }
  },
  methods: {
    getDefaultDate(tabValue) {
      if (tabValue === 'TODAY') {
        return [
          this.$moment().format('YYYY-MM-DD'),
          this.$moment().format('YYYY-MM-DD'),
        ];
      }

      if (tabValue === 'TOMORROW') {
        return [
          this.$moment().add(1, 'days').format('YYYY-MM-DD'),
          this.$moment().add(1, 'days').format('YYYY-MM-DD'),
        ];
      }

      return [
        this.$moment().subtract(30, 'days').format('YYYY-MM-DD'),
        this.$moment().format('YYYY-MM-DD'),
      ];
    },
    handleChangeTab(tab) {
      this.activeTab = tab;
      this.moreFilter.date = this.getDefaultDate(tab.value);
      this.$forceUpdate();
      this.getList();
    },
    handleChangeSearchParam() {
      this.getList();
    },
    handleSearch: debounce(function () {
      this.getList();
    }, 500),
    getList(page = 1) {
      const {
        date,
        bookingStatus,
        bookingDetailStatus,
        clinicTeams,
        roomTypes,
        rooms,
        treatments,
      } = this.moreFilter;

      // abort ongoing getList fetch to prevent duplicate fetch
      this.getListControler.abort();

      // assign new abort controller
      this.getListControler = new AbortController();

      const params = {
        page,
        limit: this.limit,
        clinicId: this.clinic ? this.clinic.value : undefined,
        [this.searchParam.value]: this.sanitizedSearch || undefined,
        clinicTeamRoles:
          this.clinicTeamRequired.length > 0
            ? `in:${this.clinicTeamRequired
                .map((role) => role.value)
                .join(',')}`
            : undefined,
        date:
          date[0] && date[1] ? `betweenDate:${date[0]},${date[1]}` : undefined,
        bookingStatus:
          bookingStatus.length > 0
            ? `in:${bookingStatus.map((status) => status.value).join(',')}`
            : undefined,
        bookingDetailStatus:
          bookingDetailStatus.length > 0
            ? `in:${bookingDetailStatus
                .map((status) => status.value)
                .join(',')}`
            : undefined,
        clinicTeamIds:
          clinicTeams.length > 0
            ? `in:${clinicTeams
                .map((clinicTeam) => clinicTeam.value)
                .join(',')}`
            : undefined,
        clinicRoomTypes:
          roomTypes.length > 0
            ? `in:${roomTypes.map((roomType) => roomType.value).join(',')}`
            : undefined,
        clinicRoomIds:
          rooms.length > 0
            ? `in:${rooms.map((room) => room.value).join(',')}`
            : undefined,
        treatmentDetailIds:
          treatments.length > 0
            ? `in:${treatments.map((treatment) => treatment.value).join(',')}`
            : undefined,
      };

      this.axios
        .get('/v2/clinical/offline-clinic-booking-detail', {
          signal: this.getListControler.signal,
          params,
        })
        .then((res) => {
          this.results = res.data.data;
        })
        .catch((err) => {
          if (err?.message === 'canceled') {
            console.info('Previous fetch canceled');
          } else {
            this.$notify({
              type: 'error',
              title: 'Error',
              text: err.response.data.message,
            });
          }
        });
    },
    getClinicNameOptions() {
      this.axios
        .get('/v2/misc/offline-clinics')
        .then((res) => {
          this.clinicNameOptions = res.data.data.rows.map((item) => ({
            value: item.id,
            label: item.name,
          }));

          // auto select clinic
          if (this.$store.state.account?.admin?.offlineClinic) {
              this.clinic = {
                value: this.$store.state.account.admin.offlineClinic.id,
                label: this.$store.state.account.admin.offlineClinic.name
              };
          }
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
    getClinicTeamRoleOptions() {
      this.axios
        .get('/v2/misc/offline-clinic-roles')
        .then((res) => {
          this.clinicTeamRoleOptions = res.data.data.rows.map((item) => ({
            value: item.slug,
            label: item.name,
          }));
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
    getRoomTypeLabel(type) {
      switch (type) {
        case 'CONSULT':
          return 'Consult Room';
        case 'FACIAL':
          return 'Facial Room';
        case 'TREATMENT':
          return 'Treatment Room';
        case 'LASER':
          return 'Laser Room';
        default:
          return '';
      }
    },
    getTreatmentDetailBgColor(bookingDetailStatus) {
      if (['ON_TREATMENT', 'DONE'].includes(bookingDetailStatus.enum)) {
        return bookingDetailStatus.backgroundColor;
      }

      return undefined;
    },
    handleEditRoom({ bookingDetailId, clinicRoom }) {
      this.isEditRoomModalOpen = true;
      this.editedRoomBookingDetailId = bookingDetailId;
      this.editedRoomSelectedValue = {
        value: clinicRoom.id,
        label: clinicRoom.name,
      };
    },
    handleEditClinicTeam({
      bookingDetailId,
      clinicTeamRoles,
      selectedTeamRole,
    }) {
      this.isEditClinicTeamModalOpen = true;
      this.editedClinicTeamBookingDetailId = bookingDetailId;
      this.editedClinicTeamSelectedValues = clinicTeamRoles;
      this.editedClinicTeamActiveTab = {
        value: selectedTeamRole.slug,
        label: selectedTeamRole.name,
      };
    },
    handleChangePage(page) {
      this.results.currentPage = page;
      this.getList(page);
    },
  },
};
</script>

<style scoped>
.bg-primary-blue {
  background-color: #0072cd !important;
}

.text-primary-blue {
  color: #0072cd !important;
}

.underline {
  text-decoration: underline;
}

.ml-0\.5 {
  margin-left: 0.5rem;
}

.mb-0\.25 {
  margin-bottom: 0.25rem;
}

.gap-0\.25 {
  gap: 0.25rem;
}

.gap-0\.5 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.tbody:hover {
  background-color: #ebf8fa;
}
</style>
